<template>
    <div class="login-form">
        <!-- Login form -->
        <form @submit.prevent="mobileForm()">
            <div class="form-group">
                <JhakaasInput inputType="text" :label="$t('mobilenumber')" inputId="userMobileno" reference="userMobileno"
                    helperText="" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" autocomplete="false"
                    :value="userMobileno" disabled required @onChange="value => userMobileno = value">
                    <template v-slot:endIcon>
                        <span style="background-color: #212121; padding: 5px;"
                            :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
                            <span class="change-button" @click="changeFrom('Mobile')">{{ $t("change") }}</span>
                        </span>

                    </template>

                </JhakaasInput>

                <JhakaasInput v-if="loginType === 'password'" inputType="password" :label="$t('password')"
                    inputId="userPassword" reference="userPassword" :helperText="formError"
                    :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" autocomplete="false" :value="userPassword"
                    @keydown.space.prevent required @onChange="value =>
                        userPassword = value">
                    <template v-slot:endIcon>
                        <span class="eye">
                            <img style="width: 20px;" :src="eyeopen" @click="toggleEye($event)" alt />
                        </span>
                    </template>
                </JhakaasInput>
            </div>
            <span class="forgot-password-span" @click="forgotPassword"
                v-if="inputtype === 'Email' || loginType === 'password'">{{ $t("forgot password") }}</span>

            <div v-if="loginType === 'otp'">
                <p class="otp_error_text">{{ $t("otp text") }}</p>
            </div>

            <div class="login-button">
                <button class="button-primary login" type="submit">
                    {{ $t("LOGIN") }}
                </button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import { _providerId } from "@/provider-config.js";
import FirebaseActions from "@/mixins/FirebaseActions.js";
import { showSnackBar } from "@/utilities";
export default {
    props: {
        input: {
            type: String,
        },
        inputtype: {
            type: String,
        },
        formData: {
            type: String,
        },
    },
    components: {
        JhakaasInput: () => import("../../../utils/JhakaasInput.vue"),
    },

    data() {
        return {
            userMobileno: "",
            passwordPlaceholder: "",
            userPassword: null,
            formError: null,
            enableLoader: false,
            resendLink: null,
            resendText: null,
            userPin: [],
            profilePin: "",
            loginType: "password",
            eyeopen: require("@/assets/icons/eye.svg"),
            eyeClose: require("@/assets/icons/eye-close.svg"),
            providerUniqueId: _providerId,
            localDisplayLang: null,
            isCaptchaEnabled: false,
            loginCaptcha: null,
            captchaImage: null,
            captchaReferenceId: null,
        };
    },
    computed: {
        ...mapGetters(["appConfig", "gettoken", "subscriberid", "getRtl", "country", "profileid", "subscriptionList"]),

    },
    watch: {
        formError(val) {
            if (val) {
                setTimeout(() => {
                    this.formError = null;
                }, 5000);
            }
        },
        subscriberid(val) {
            if (val) {
                this.$emit("closePopup");
            }
        },
        loginType(val) {
            if (val === "otp") {
                this.setupPin();
                this.logingAPIforOTP();
                // this.resend();
            }
        },
    },
    created() {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

        // Checking for change of language in local storage value
        eventBus.$on("check-local-storage", (data) => {
            this.localDisplayLang = data;
        });
    },

    mounted() {
        this.userMobileno = localStorage.getItem("getUserPasswordNumber");
        var allMetaElements = document.getElementsByTagName('meta');
        document.title = "Login to your Account at Cspace";
        for (var metaValue = 0; metaValue < allMetaElements.length; metaValue++) {
            if (allMetaElements[metaValue].getAttribute("name") == "description") {
                allMetaElements[metaValue].setAttribute('content', "Login to your account with a valid registered email address or mobile number at Cspace website.");
            }

            if (allMetaElements[metaValue].getAttribute("property") == "og:description") {
                allMetaElements[metaValue].setAttribute('content', "Login to your account with a valid registered email address or mobile number at Cspace website.");
            }
            if (allMetaElements[metaValue].getAttribute("name") == "twitter:description") {
                allMetaElements[metaValue].setAttribute('content', "Login to your account with a valid registered email address or mobile number at Cspace website.");
            }
            if (allMetaElements[metaValue].getAttribute("name") == "keyword") {
                allMetaElements[metaValue].setAttribute('content', "Cspace account login, login to account,");
            }
            if (allMetaElements[metaValue].getAttribute("property") == "og:title") {
                allMetaElements[metaValue].setAttribute('content', "Login to your Account at Cspace");
            }
            if (allMetaElements[metaValue].getAttribute("name") == "twitter:title") {
                allMetaElements[metaValue].setAttribute('content', "Login to your Account at Cspace");
            }
        }

        eventBus.$on("login-response", (response) => {
            let GA = {};
            if (response.reason) {
                if (response.errorcode === 6110) {
                    this.resendLink = this.$t(response.reason);
                    this.resendText = this.resendText = this.inputtype === "Email" ? this.$t("Verify") : this.$t("Verify");
                } else {
                    if (response.errorcode === 6005 || response.reason === "Invalid Otp") {
                        this.formError = this.$t(response.reason);
                        this.userPin = [];
                        return;
                    }
                    this.formError = this.$t(response.reason);
                    this.userPin = [];

                    if (response.errorcode !== 7303) {
                        this.isCaptchaEnabled = false;
                        this.loginCaptcha = "";
                        this.captchaImage = null;
                        this.captchaReferenceId = null;
                    }
                }

                //constructing google analytics details.
                GA.login_mode = this.inputtype === "Email" ? "Email" : "Mobile";
                GA.status = "Failed";
                GA.error_reason = response.reason;
            } else if (response.successtype === "CAPTCHA") {
                this.isCaptchaEnabled = true;

                this.captchaImage = response && response.success ? response.success.payload : "";
                this.captchaReferenceId = response && response.success ? response.success.referenceid : "";

            } else {
                //constructing google analytics details.
                GA.login_mode = this.inputtype === "Email" ? "Email" : "Mobile";
                GA.status = "Success";
                // let message = "Login Successfull"
                // this.snackbarMessage(message);
                localStorage.setItem("sessionToken", response.success);
                // eventBus.$emit("subscriberDetails");
                localStorage.setItem("isMultipleProfileEnabled", this.appConfig.featureEnabled.isMobileOtpLoginEnabled);
                if (this.appConfig.featureEnabled.isMultipleProfileEnabled) {
                    let currentLanguage = this.getCurrentLanguageUrlBase();


                    this.listProfiles().then((profileResponse) => {
                        if (profileResponse.data && profileResponse.data.totalcount > 1) {
                            this.parentalControlStatus()
                                .then((data) => {
                                    if (data.reason) {
                                        return;
                                    }
                                    if (data.data.isenabled) {
                                        localStorage.setItem("parentalControlEnabled", data.data.isenabled);
                                    }
                                });
                            this.$router.push({ name: "switchProfile", params: { lang: currentLanguage, renderCancel: false }, query: { "islogin": true } })
                        } else {
                            if ( window.selectedPlanInfomationRouting && !(this.subscriptionList && this.subscriptionList.length > 0)) {
                                this.$router.push({ name: "Home", params: { lang: currentLanguage } });

                                eventBus.$emit("open-home-rzp-modal", window.selectedPlanInfomationRouting);
                                window.selectedPlanInfomationRouting = "";
                                } else {
                                    this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : this.$route.path });
                                }

                                window.storeAfterLoginPath = ""

                            }
                    });
                }

                const appConfig = this.appConfig;
                let convertingMobileViewControllerToBoolean;
                if (appConfig && appConfig.mobileViewController) {
                    convertingMobileViewControllerToBoolean = JSON.parse(this.appConfig.mobileViewController.toLowerCase());
                }
                if (convertingMobileViewControllerToBoolean) {
                    this.checkMobileWeb();
                }
            }

            const browser = this.getPlatformType();

            GA.platform = this.getPlatform();

            GA.platform_type = browser.browser;

            console.log("THE LOGIN GA", GA);
            //logging signup google analytics event.
            this.loginEvent(GA);

        });

        eventBus.$on("resend-response", (response) => {
            this.resendLink = null;
            if (response.reason) {
                this.formError = response.reason;
            }
        });

        this.passwordPlaceholder = this.$t("password");
        this.setupForm();

        if (this.inputtype === "Mobile") {
            this.setupPin();
        }
        eventBus.$on("auth-close", () => {
            this.$emit("closePopup");
        });

        eventBus.$on("social-captch-event", (data) => {
            let info = {
                data,
            };
            info.formType = "socialCaptcha";
            this.$emit("change", info);
        });

        eventBus.$on("forgotpassword-response", (response) => {
            if (response.reason) {
                if (response.errorcode === 6110) {
                    this.resendLink = this.$t(response.reason);
                    this.resendText = this.inputtype === "Email" ? this.$t("Verify") : this.$t("Verify");
                } else {
                    this.formError = this.$t(response.reason);
                }
            } else {
                eventBus.$emit("auth-close");
                this.triggerSnackbarEvent();
                this.triggerForgotPasswordEvent();
            }
        });
    },


    methods: {
        ...mapMutations(["setUserInfo", "setToken", "setProfileid"]),
        ...mapActions(["actGetLogin", "actGetSubscriber", "getCaptcha", "login", "listProfiles", "parentalControlStatus"]),

        getAnotherCaptcha() {
            this.getCaptchaImage();
        },

        handleResendLink() {
            if (this.appConfig.featureEnabled.isCaptchaEnabled) {
                let template = {
                    input: this.inputtype === 'Email' ? "email" : 'mobile',
                    formType: "verifyResendCaptcha",
                    email: this.userEmail ? this.userEmail : "",
                    mobileno: this.userMobileno ? this.userMobileno : ""
                };

                this.$emit("change", template);
            } else {
                if (this.inputtype === 'Email') {
                    this.resend()
                    this.registerEmail()
                }
            }

        },


        checkMobileWeb() {
            let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);

            if (!isMobile) {
                const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

                if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
                    isMobile = true;
                }
            }
            if (isMobile && localStorage.getItem("isMobile") == "true") {
                let payload = {
                    state: true,
                    message: "Login successfull",
                    color: "green",
                };
                eventBus.$emit("snackBarMessage", payload);
                eventBus.$emit("triggerInitView", payload);
                let currentLanguage = this.getCurrentLanguageUrlBase();
                this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
            }
        },

        setupForm() {
            if (this.input && this.inputtype === "Email") {
                // this.$refs.email.disabled = true;
                this.userEmail = this.input;
                //  let input = document.getElementById("userEmail");
                //input.style.backgroundColor = "#5C5C5D";
                //input.style.opacity = "0.3";
            }

            if (this.input && this.inputtype === "Mobile") {
                // this.$refs.mobileno.disabled = true;
                this.userMobileno = this.input;
            }
        },

        snackbarMessage(message) {
            console.log("snackbar message called");
            showSnackBar(this.$t(message));
        },

        setupPin() {
            setTimeout(() => {
                this.getId = document.getElementById("pininputs");
                this.pinFunctionality(this.getId);
            }, 1000);
        },

        changeFrom(data) {
            let info = {
                formType: "lookup",
                inputtype: data,
            };
            this.$emit("change", info);
        },

        grabPin() {
            if (this.userPin.length > 3) {
                let pin = "";
                this.userPin.forEach((element) => {
                    pin += parseInt(element);
                });
                this.profilePin = pin;
                // console.log("profilePin", this.profilePin);
            }
        },

        resend(state) {
            if (this.appConfig.featureEnabled.isCaptchaEnabled) {
                let template = {
                    input: this.inputtype === 'Email' ? "email" : 'mobile',
                    formType: "verifyResendCaptcha",
                    mobileno: this.userMobileno ? this.userMobileno : ""
                };

                this.$emit("change", template);
            } else {

                // this.showTimer = state;
                this.setupOtpInterval
                let payload = {};
                if (this.inputtype === "Mobile") {
                    payload.mobileno = this.userMobileno;
                } else {
                    payload.email = this.userEmail;
                }
                eventBus.$emit("resend-link", payload);
            }

        },

        registerMobile() {
            let template = {
                input: this.userMobileno,
                formType: "mobile",
            };
            this.$emit("change", template);
        },

        mobileForm() {
            if (!this.userPassword) {
                this.formError = this.$t("Please enter Password");
                return;
            }
            let payload = {
                params: {
                    devicetype: "PC",
                    deviceos: this.getDeviceOS(),
                    mobileno: this.userMobileno,
                    country: this.country ? this.country.CountryCode : "",
                    password: this.userPassword
                },
            };

            eventBus.$emit("subscriberLogin", payload);
        },

        forgotPassword() {
            if (this.appConfig.featureEnabled.isCaptchaEnabled) {
                this.triggerForgotPasswordEvent();
            } else {
                let payload = {};

                if (this.inputtype === "Email") {
                    payload.email = this.userEmail;
                } else {
                    payload.mobileno = this.userMobileno;
                }
                eventBus.$emit("forgotPasswordRequest", payload);
            }
        },

        triggerForgotPasswordEvent() {
            let payload = {
                input: this.inputtype === "Email" ? this.userEmail : this.userMobileno,
                inputtype: this.inputtype,
            };
            eventBus.$emit("showForgotPassword", payload);
        },

        triggerSnackbarEvent() {
            let payload = {
                state: true,
                message:
                    this.inputtype === "Email" ? "OTP has been sent to your mail id" : "OTP has been sent your mobile number",
                color: "#ff9600",
            };
            eventBus.$emit("snackBarMessage", payload);
        },

        validateOtp() {
            this.formError = null;
            if (!this.profilePin) {
                this.formError = this.$t("Please Enter OTP");
                return false;
            }
            return true;
        },
    },

    beforeDestroy() {
        eventBus.$off("login-response");
        eventBus.$off("resend-response");
        eventBus.$off("forgotpassword-response");
        eventBus.$off("subscriberDetails-set-success");

    },
    mixins: [Utility, googleAnalytics, cleverTap, FirebaseActions],
    events: ["onInputChange"],
};
</script>
  
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./login.scss"
</style>
  