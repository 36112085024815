<template>
    <div class="mobile-form">
      <form @submit.prevent="mobileVerifySignup">
        <div class="form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
          
          <div>
            <p class="verification-heading">{{ $t("Verify Mobile Number") }}</p>
            <div class="email-verification">
              <p class="vlive-text">{{ $t("Please Enter 6 Digit OTP sent to your Mobile Number") }} </p>
            </div>
          </div>
          <div class="vlive-form">
           <p  class="vlive-user-input"> {{ userMobileno }} </p>
           <P class="change-button" @click="changeFrom('mobileno')">{{ $t("Change") }}</P>
          </div>
  
  
          <div class="form-control">
            <PinLayout ref="pinLayout" value="" pinCount="6" @onChange="updatePin" @onCompleted="pinCompleted"
              class="pinboxes" />
  
          </div>
          <div class="form-control">
            <p v-if="timeRemaining" class="mb-10 mt-10 subtitle text-center">
              {{ $t("Resend Code in") }} <span style="color:#777; font-size: 14px;">00:{{ timeRemaining }}</span>
            </p>
            <p @click="resend" v-else class="mb-10 subtitle text-center">
              {{ $t("Didn't receive OTP?") }} <span style="color:#777; font-size: 14px; cursor: pointer;">{{ $t("Resend") }}</span>
            </p>
          </div>
        </div>
  
        <div class="login-button">
          <button class="button-primary signup" type="submit">
            {{ $t("SIGNUP") }}
          </button>
          <div class="dynamic-password" v-show="activeMobile">
            <p>{{ $t("USE PASSWORD") }}</p>
          </div>
        </div>
      </form>
    </div>
  </template>
      
  <script>
  import Utility from "@/mixins/Utility.js";
  import { eventBus } from "@/eventBus";
  import { store } from "@/store/store";
  import { mapGetters, mapActions } from "vuex";
  import { _providerId } from "../../../../provider-config";
  
  export default {
    props: ["input", "formData"],
    data() {
      return {
        userMobileno: "",
        userPin: [],
        profilePin: "",
        formError: null,
        localDisplayLang: null,
        timeRemaining: "60",
        otpTimer: null,
        inputType: "'text'",
        activeMobile: {}
      };
    },
    watch: {
      formError(val) {
        if (val) {
          setTimeout(() => {
            this.formError = null;
          }, 5000);
        }
      },
      timeRemaining: {
        handler(newValue, oldValue) {
          if (newValue === "") {
            this.clearOtpInterval(newValue);
          }
        },
      },
      deep: true,
    },
  
    computed: {
      ...mapGetters(["country", "appConfig"]),
    },
  
    created() {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
      eventBus.$on("auth-close", () => {
        this.$emit("closePopup");
      });
  
      eventBus.$on("login-response", (response) => {
  
        localStorage.setItem("sessionToken", response.success);
  
        let currentLanguage = this.getCurrentLanguageUrlBase();
          let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
        if (currentLanguage == defaultLanguage) {
            currentLanguage = null;
          }
  
        if ( window.selectedPlanInfomationRouting ) {
              this.$router.push({ name: "Home", params: { lang: currentLanguage } });
  
              eventBus.$emit("open-home-rzp-modal", window.selectedPlanInfomationRouting);
              window.selectedPlanInfomationRouting = "";
          } else {
            this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : this.$route.path });
          }
  
          window.storeAfterLoginPath = "";
  
    
  
      })
  
  
  
      eventBus.$on("signup-lookup-res", (res) => {
        console.log(res)
        this.activeMobile = res.isPasswordSet;
      })
  
    },
    mounted() {
      this.userMobileno = this.input;
      this.signUPOtpPayload()
      this.setupOtpInterval();
      this.resend = this.throttle(this.resend, 3000);
      this.setupForm();
      this.setupPin();
  
      console.log("THIS IS FROM DATA --- > ", this.formData);
    },
    methods: {
      ...mapActions(["signup", "newEmailconfirm", "listProfiles", "parentalControlStatus"]),
  
      throttle(fn, wait) {
        let throttled = false;
        return function (...args) {
          if (!throttled) {
            fn.apply(this, args);
            throttled = true;
            setTimeout(() => {
              throttled = false;
            }, wait);
          }
        }
      },
      resendCaptcha() {
  
        if (this.appConfig.featureEnabled.isCaptchaEnabled) {
  
          let template = {
            input: this.inputtype === 'Email' ? "email" : 'mobile',
            formType: "verifyResendCaptcha",
            mobileno: this.userMobileno ? this.userMobileno : ""
          };
  
          this.$emit("change", template);
        } else {
          this.resend();
        }
  
      },
  
  
      resend() {
        this.clearOtpInterval();
        this.$refs.pinLayout.clearOtpBoxes();
        let payload = {
          mobileno: this.input,
        };
        this.setupOtpInterval();
        this.timeRemaining = 60;
  
        eventBus.$emit("resend-link", payload);
      },
      clearOtpInterval(timeRemaining) {
        this.timeRemaining = timeRemaining;
        clearInterval(this.otpTimer);
        this.otpTimer = null;
      },
      setupOtpInterval() {
        this.timeRemaining = "60";
        this.otpTimer = setInterval(() => {
          let timeRemaining;
          try {
            timeRemaining = parseInt(this.timeRemaining);
          } catch (NanException) {
            timeRemaining = "";
          }
          if (timeRemaining === "") {
            this.clearOtpInterval(timeRemaining);
            return;
          }
  
          timeRemaining = timeRemaining - 1;
  
          let slug = timeRemaining.toString();
          if (slug.length < 2) {
            slug = "0" + slug;
          }
          this.timeRemaining = slug;
  
          if (timeRemaining <= 0) {
            this.clearOtpInterval("");
          }
        }, 1000);
      },
      open(newValue, oldValue) {
        if (newValue) {
          this.setupOtpInterval();
        } else {
          this.clearOtpInterval("");
        }
      },
  
  
      setupForm() {
        console.log("getinput", this.input)
  
        let input = document.getElementById("userMobileno");
        input.style.backgroundColor = "#5C5C5D";
        input.style.opacity = "0.3";
      },
  
      pinCompleted(pin) {
        this.profilePin = pin
        // this.emailVerify()
      },
      updatePin(value) {
        this.userPin = value.split('')
      },
  
      changeFrom(data) {
        let info = {
          formType: "lookup",
          inputtype: data
        };
        this.$emit("change", info);
      },
  
      setupPin() {
        setTimeout(() => {
          this.getId = document.getElementById("pininputs");
          this.pinFunctionality(this.getId);
        }, 1000);
      },
  
      grabPin() {
        if (this.userPin.length > 3) {
          let pin = "";
          this.userPin.forEach((element) => {
            pin += element;
          });
          this.profilePin = pin;
        }
      },
      signUPOtpPayload() {
        let payload = {
          devicetype: "PC",
          deviceos: this.getDeviceOS(),
          mobileno: this.userMobileno,
          country: this.country ? this.country.CountryCode : ""
        };
        this.signup(payload)
          .then((data) => {
            if (data.data.reason) {
              this.formError = this.$t(data.data.reason)
            }
            else if (data.data.success) {
  
            }
          })
          .catch((error) => {
            console.log(" LoginAccountErrror -> ", error);
          });
      },
  
  
  
      mobileVerifySignup() {
        if (!this.verifyOtp(this.profilePin)) return;
        const payload = {
          mobileno: this.userMobileno,
          otp: this.profilePin,
          providerid: _providerId,
        };
  
        this.newEmailconfirm(payload)
          .then((response) => {
            if (response.data && response.data.success) {
              let loginPayload = {
                params: {
                  devicetype: "PC",
                  deviceos: this.getDeviceOS(),
                  mobileno: this.userMobileno,
                  country: this.country ? this.country.CountryCode : "",
                  otp: this.profilePin,
                },
              };
              eventBus.$emit("subscriberLogin", loginPayload);
  
            } else if (response.data && response.data.reason === "Incorrect OTP") {
              this.formError = this.$t("Incorrect OTP. Please try again");
              this.$refs.pinLayout.clearOtpBoxes();
              return;
            } else {
              this.formError = this.$t("Incorrect OTP. Please try again");
              this.$refs.pinLayout.clearOtpBoxes();
              return;
            }
          })
          .catch((error) => {
            console.log("Error in newEmailconfirm API call: ", error);
          });
      },
  
  
  
      verifyOtp(pin) {
        this.formError = null;
        if (!pin) {
          this.formError = this.$t("Please Enter OTP");
          return false;
        }
        return true;
      },
    },
    beforeDestroy() {
  
    },
    components: {
      PinLayout: () => import("@/components/utils/PinLayout.vue"),
    },
    mixins: [Utility],
  };
  </script>
      
  <style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  @import "./MobileSignUp.scss";
  </style>
      