<template>
    <div class="mobile-form">
      <form @submit.prevent="mobileVerifyLogin">
        <div class="form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
          <div>
            <p class="verification-heading">{{ $t("Verify Mobile Number") }}</p>
            <div class="email-verification">
              <p class="vlive-text">{{ $t("Please Enter 6 Digit OTP sent to your Mobile Number") }} </p>
            </div>
          </div>
          <div class="vlive-form">
            <p class="vlive-user-input"> {{ userMobileno }} </p>
            <P class="change-button" @click="changeFrom('mobileno')">{{ $t("Change") }}</P>
          </div>
  
          <div class="form-control">
            <PinLayout ref="pinLayout" value="" pinCount="6" @onChange="updatePin" @onCompleted="pinCompleted"
              class="pinboxes" />
  
          </div>
          <div class="form-control">
            <p v-if="timeRemaining" class="mb-10 mt-10 subtitle text-center">
              {{ $t("Resend Code in") }} <span style="color:#777; font-size: 14px;">00:{{ timeRemaining }}</span>
            </p>
            <p @click="resendOtpLink" v-else class="mb-10 subtitle text-center">
              {{ $t("Didn't receive OTP?") }} <span style="color:#777; font-size: 14px; cursor: pointer; ">{{ $t("Resend") }}</span>
            </p>
          </div>
        </div>
  
        <div class="disclamier">
          <p class="otp_error_text">{{ $t("otp text") }}</p>
        </div>
  
        <div class="login-button">
          <button class="button-primary signup" type="submit">
            {{ $t("Verify") }}
          </button>
          <div class="dynamic-password" v-if="activeMobile">
            <p @click="changeToLogin('mobileno')">{{ $t("USE PASSWORD") }}</p>
          </div>
        </div>
      </form>
    </div>
  </template>
      
  <script>
  import Utility from "@/mixins/Utility.js";
  import { eventBus } from "@/eventBus";
  import { store } from "@/store/store";
  import { mapGetters, mapActions } from "vuex";
  import { showSnackBar } from "@/utilities";
  import { _providerId } from "../../../../provider-config";
  
  export default {
    props: ["input", "formData"],
    data() {
      return {
        userMobileno: "",
        userPin: [],
        profilePin: "",
        formError: null,
        localDisplayLang: null,
        inputType: "'text'",
        activeMobile: {},
        timeRemaining: "60",
        otpTimer: null,
        isMobileVerificationPending: false,
      };
    },
    watch: {
      formError(val) {
        if (val) {
          setTimeout(() => {
            this.formError = null;
          }, 5000);
        }
      },
      timeRemaining: {
        handler(newValue, oldValue) {
          if (newValue === "") {
            this.clearOtpInterval(newValue);
          }
        },
      },
      deep: true,
  
    },
  
    computed: {
      ...mapGetters(["country", "appConfig", "subscriptionList"]),
    },
  
    created() {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
      eventBus.$on("auth-close", () => {
        this.$emit("closePopup");
      });
  
      eventBus.$on("login-response", (response) => {
            if (response.reason) {
                  if (response.errorcode == 6110) {
                    this.isMobileVerificationPending = true;
                  } else {
                    this.formError = this.$t(response.reason);
                    this.$refs.pinLayout.clearOtpBoxes();
                  }
            } else {
              localStorage.setItem("sessionToken", response.success);
                localStorage.setItem("isMultipleProfileEnabled", this.appConfig.featureEnabled.isMobileOtpLoginEnabled);
                if (this.appConfig.featureEnabled.isMultipleProfileEnabled) {
                  let currentLanguage = this.getCurrentLanguageUrlBase();
  
  
                  this.listProfiles().then((profileResponse) => {
                    if (profileResponse.data && profileResponse.data.totalcount > 1) {
                      this.parentalControlStatus()
                      .then((data) => {
                        if (data.reason) {
                          return;
                        }
                        if (data.data.isenabled) {
                          localStorage.setItem("parentalControlEnabled", data.data.isenabled);
                        }
                      });
                      this.$router.push({name: "switchProfile", params: { lang: currentLanguage, renderCancel: false }, query:{"islogin": true}})
                      } else {
              
                      this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : this.$route.path });
                      if ( window.selectedPlanInfomationRouting && !(this.subscriptionList && this.subscriptionList.length > 0)) {
                        setTimeout (() => {
                          eventBus.$emit("open-home-rzp-modal", window.selectedPlanInfomationRouting);
                          window.selectedPlanInfomationRouting = "";
                        }, 500)
                      }
                      window.storeAfterLoginPath = "";                    
  
                    }
                  });
            }
          }
      })
      eventBus.$on("signup-lookup-res", (res) => {
        this.activeMobile = res.isPasswordSet;
      })
  
    },
    mounted() {
      this.userMobileno = this.input;
      this.loginOtpPayload();
      this.loginOtpPayload = this.throttle(this.loginOtpPayload, 3000);
      this.setupOtpInterval();
      this.setupForm();
      this.setupPin();
    },
    methods: {
      ...mapActions(["login", "resendLink", "newEmailconfirm", "listProfiles", "parentalControlStatus"]),
  
  
      throttle(fn, wait) {
        let throttled = false;
        return function (...args) {
          if (!throttled) {
            fn.apply(this, args);
            throttled = true;
            setTimeout(() => {
              throttled = false;
            }, wait);
          }
        }
      },
  
      clearOtpInterval(timeRemaining) {
        this.timeRemaining = timeRemaining;
        clearInterval(this.otpTimer);
        this.otpTimer = null;
      },
      setupOtpInterval() {
        this.timeRemaining = "60";
        this.otpTimer = setInterval(() => {
          let timeRemaining = parseInt(this.timeRemaining);
          if (isNaN(timeRemaining) || timeRemaining <= 0) {
            this.clearOtpInterval("");
          } else {
            timeRemaining = timeRemaining - 1;
  
            let slug = timeRemaining.toString();
            if (slug.length < 2) {
              slug = "0" + slug;
            }
            this.timeRemaining = slug;
          }
        }, 1000);
      },
  
      open(newValue, oldValue) {
        if (newValue) {
          this.setupOtpInterval();
        } else {
          this.clearOtpInterval("");
        }
      },
  
      verificationResendSms() {
        let payload = {
          mobileno: this.userMobileno,
        }
        this.resendLink(payload)
          .then((response) => {
            if (response.data && response.data.success) {
             
            }  else if (response.data && response.data.errorcode === 5212) {
              this.formError = showSnackBar(this.$t(response.data.reason), this.$t('DISMISS'));
            }
          })
      },
  
  
      resendCaptcha() {
  
        if (this.appConfig.featureEnabled.isCaptchaEnabled) {
  
          let template = {
            input: this.inputtype === 'Email' ? "email" : 'mobile',
            formType: "verifyResendCaptcha",
            mobileno: this.userMobileno ? this.userMobileno : ""
          };
  
          this.$emit("change", template);
        } else {
  
        }
  
      },
  
  
      changeToLogin(data) {
        let info = {
          formType: "userPasswordLogin",
          inputtype: data
        };
        this.$emit("change", info);
      },
  
      setupForm() {
        console.log("getinput", this.input)
  
        let input = document.getElementById("userMobileno");
        input.style.backgroundColor = "#5C5C5D";
        input.style.opacity = "0.3";
      },
  
      pinCompleted(pin) {
        this.profilePin = pin
      },
      updatePin(value) {
        this.userPin = value.split('')
      },
  
      changeFrom(data) {
        let info = {
          formType: "lookup",
          inputtype: data
        };
        this.$emit("change", info);
      },
  
  
  
      setupPin() {
        setTimeout(() => {
          this.getId = document.getElementById("pininputs");
          this.pinFunctionality(this.getId);
        }, 1000);
      },
  
      grabPin() {
        if (this.userPin.length > 3) {
          let pin = "";
          this.userPin.forEach((element) => {
            pin += element;
          });
          this.profilePin = pin;
        }
      },
  
      resendOtpLink() {
        this.loginOtpPayload(); 
        this.setupOtpInterval();
        this.timeRemaining = 60;
        this.$refs.pinLayout.clearOtpBoxes();
      },
  
      loginOtpPayload() {
        localStorage.setItem("getUserPasswordNumber", this.userMobileno)
        this.clearOtpInterval();
        this.timeRemaining = 60;
        let payload = {
          devicetype: "PC",
          deviceos: this.getDeviceOS(),
          mobileno: this.userMobileno,
          country: this.country ? this.country.CountryCode : "",
        };
        this.login(payload)
          .then((response) => {
            if (response.data && response.data.success) {
              this.mobileVerifyLogin();
            } else if (response.data && response.data.errorcode == 6004) {
              return;
            } else if (response.data && response.data.errorcode == 5212) {
              this.formError = showSnackBar(this.$t(response.data.reason), this.$t('DISMISS'));
            } else {
              this.formError = "";
              this.isMobileVerificationPending = true;
              this.verificationResendSms();
              // this.timeRemaining = 60;
              // this.setupOtpInterval();
            }
          })
      },
  
      verifyMobileVerificationPending() {
        if (!this.verifyOtp(this.profilePin)) return;
        let payload = {
          mobileno: this.userMobileno,
          otp: this.profilePin,
          providerid: _providerId,
        };
  
        this.newEmailconfirm(payload)
          .then((response) => {
            if (response.data && response.data.success) {
              this.isMobileVerificationPending = false;
              this.mobileVerifyLogin()
            } else {
              this.formError = this.$t(response.data.reason);
              this.$refs.pinLayout.clearOtpBoxes();
          
            }
          })
  
      },
  
      mobileVerifyLogin() {
        if (!this.verifyOtp(this.profilePin)) return;
        if (this.isMobileVerificationPending) {
          this.verifyMobileVerificationPending()
          return;
        }
        let loginPayload = {
          params: {
            devicetype: "PC",
            deviceos: this.getDeviceOS(),
            mobileno: this.userMobileno,
            country: this.country ? this.country.CountryCode : "",
            otp: this.profilePin,
          },
        };
  
        eventBus.$emit("subscriberLogin", loginPayload);
      },
  
  
      verifyOtp(pin) {
        this.formError = null;
        if (!pin) {
          this.formError = this.$t("Please Enter OTP");
          return false;
        }
        return true;
      },
    },
    beforeDestroy() {
      eventBus.$off("login-response");
      eventBus.$off("signup-lookup-res");
    },
    components: {
      PinLayout: () => import("@/components/utils/PinLayout.vue"),
      JhakaasInput: () => import("@/components/utils/JhakaasInput.vue"),
    },
    mixins: [Utility],
  };
  </script>
      
  <style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  @import "./MobileLogin.scss";
  </style>
      